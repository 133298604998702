import { AbstractStruct } from '@pushly/models/lib/structs/abstract-struct'
import { Expose } from 'class-transformer'
import { NativeApnsSendIntegrationConfig } from '@pushly/models/lib/structs/send-integration-configurations/native-apns-send-integration-config'
import { NativeFcmSendIntegrationConfig } from '@pushly/models/lib/structs/send-integration-configurations/native-fcm-send-integration-config'

export class PlatformUserDomainRecord extends AbstractStruct {
    @Expose()
    public readonly id: number

    @Expose()
    public readonly name: string

    @Expose()
    public readonly displayName: string

    @Expose()
    public readonly accountId: number

    @Expose()
    public readonly accountName: string

    @Expose()
    public readonly accountFlags: string[]

    @Expose()
    public readonly timezone: string

    @Expose()
    public readonly flags: string[]

    @Expose()
    public readonly defaultIconUrl: string

    @Expose()
    public readonly nativeApnsConfiguration: NativeApnsSendIntegrationConfig

    @Expose()
    public readonly nativeFcmConfiguration: NativeFcmSendIntegrationConfig
}
